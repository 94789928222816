<template>
  <div class="Organ bg-white">
    <Row class="px-1">
      <i-col span="18" class="align-justify">
        <Button type="default" icon="md-refresh" size="large" class="mr-1"></Button>
        <Select
          class="mr-1"
          size="large"
          style="width: 10rem"
          v-model="consultantStr"
          placeholder="选择咨询师"
          clearable
          filterable
          @on-clear="clearConsult"
          @on-select="selectConsultant"
        >
          <Option
            v-for="item in consultantList"
            :value="item.value"
            :key="item.value"
          >{{ item.label }}</Option>
        </Select>
        <Select
          class="mr-1"
          size="large"
          style="width: 10rem"
          v-model="statusStr"
          placeholder="选择预约状态"
          clearable
          @on-clear="clearStatus"
          @on-select="selectReserveStatus"
        >
          <Option v-for="item in statusList" :value="item.value" :key="item.value">{{ item.label }}</Option>
        </Select>
        <i-input
          v-model="inputValue"
          size="large"
          placeholder="输入咨询人姓名查找"
          style="width: 15rem"
          class="el-inline mr-1"
        >
          <Button slot="append" icon="md-search" style="width: 2.5rem" @click="handleSearch"></Button>
        </i-input>
      </i-col>
      <i-col span="24">
        <Table
          class="mt-1"
          border
          stripe
          context-menu
          show-context-menu
          ref="organTable"
          :loading="searching"
          :columns="tColumns"
          :data="tData"
          :height="theight"
          @on-contextmenu="handleContextMenu"
        >
          <div slot="contextMenu">
            <DropdownItem @click.native="showDetail">查看详情</DropdownItem>
            <DropdownItem
              style="color: #2d8cf0"
              v-if="this.submitStatus"
              @click.native="adjustTime"
            >调整时间并接受</DropdownItem>
            <DropdownItem
              style="color: #19be6b"
              v-if="this.submitStatus"
              @click.native="acceptReserve"
            >接受</DropdownItem>
            <DropdownItem
              style="color: #ed4014"
              v-if="this.submitStatus"
              @click.native="rejectReserve"
            >拒绝</DropdownItem>
          </div>
        </Table>
        <Page
          ref="usersPagination"
          :total="recordTotal"
          :page-size="20"
          @on-change="changePage"
          @on-page-size-change="changeSize"
          show-total
          show-sizer
          show-elevator
          class="p-1 align-right"
        />
      </i-col>
    </Row>
  </div>
</template>

<script>
export default {
  name: "Reserve",
  data() {
    return {
      pageSize: 20,
      recordTotal: 0,
      pageNum: 1,
      tData: [],
      inputValue: "",
      searching: true,
      deOrRe: "移除",
      theight: window.innerHeight - 275,
      isShow: true,
      recordTotal: 20,
      tColumns: [
        {
          type: "selection",
          width: 35,
          align: "center",
        },
        {
          type: "index",
          title: "序号",
          width: 45,
          align: "center",
        },
        {
          title: "咨询人",
          key: "reserveUserName",
          minWidth: 60,
        },
        {
          title: "联系电话",
          key: "reserveUserPhone",
          minWidth: 60,
        },
        {
          title: "性别",
          key: "reserveUserGender",
          minWidth: 20,
          render: (h, params) => {
            return h("span", this.myjs.getGender(params.row.reserveUserGender));
          },
        },
        {
          title: "年龄",
          key: "reserveUserYear",
          minWidth: 20,
          render: (h, params) => {
            let age = this.myjs.getAge(params.row.reserveUserYear, new Date());
            return h("span", age);
          },
        },
        {
          title: "预约咨询师",
          key: "consultantName",
          minWidth: 60,
        },
        {
          title: "咨询日期",
          key: "scheduDate",
          minWidth: 60,
        },
        {
          title: "咨询时间",
          key: "scheduStartTime",
          minWidth: 60,
          render: (h, params) => {
            let time =
              params.row.scheduStartTime + "-" + params.row.scheduEndTime;
            return h("span", time);
          },
        },
        {
          title: "预约地址",
          key: "address",
          minWidth: 60,
          tooltip: true,
        },
        {
          title: "房间号",
          key: "roomCode",
          minWidth: 60,
        },
        {
          title: "咨询方式",
          key: "consultType",
          minWidth: 60,
          render: (h, params) => {
            const row = params.row;
            const color =
              row.consultType === 0
                ? "primary"
                : row.consultType === 1
                ? "success"
                : "warning";
            const text =
              row.consultType === 0
                ? "面对面咨询"
                : row.consultType === 1
                ? "视频咨询"
                : "语音咨询";
            return h(
              "Tag",
              {
                props: {
                  type: "dot",
                  color: color,
                },
              },
              text
            );
          },
        },
        {
          title: "预约状态",
          key: "reserveStatus",
          minWidth: 60,
          render: (h, params) => {
            const row = params.row;
            const color =
              row.reserveStatus === 1
                ? "primary"
                : row.reserveStatus === 2
                ? "success"
                : row.reserveStatus === 3
                ? "error"
                : "warning";
            const text =
              row.reserveStatus === 1
                ? "待确认"
                : row.reserveStatus === 2
                ? "已接受"
                : row.reserveStatus === 3
                ? "已拒绝"
                : "已取消";
            return h(
              "Tag",
              {
                props: {
                  type: "dot",
                  color: color,
                },
              },
              text
            );
          },
          align: "center",
        },
      ],
      submitStatus: false,
      consultantStr: "",
      statusStr: "",
      consultantList: [],
      statusList: [
        {
          label: "待确认",
          value: "1",
        },
        {
          label: "已接受",
          value: "2",
        },
        {
          label: "已拒绝",
          value: "3",
        },
        {
          label: "已取消",
          value: "4",
        },
      ],
      reserveStatus: -1,
      consultantId: -1,
    };
  },
  mounted() {
    window.addEventListener(
      "resize",
      () => (this.theight = window.innerHeight - 275),
      false
    );
    this.$get("Reserve/GetConsultantBySelect", {
      requestName: "jaksldjaksdnq",
    }).then((res) => {
      this.consultantList = res.data.responseList;
    });
    this.getReserveList();
  },
  methods: {
    clearConsult() {
      this.consultantId = -1;
      this.getReserveList();
    },
    selectConsultant(e) {
      this.consultantId = e.value;
      this.getReserveList();
    },
    clearStatus() {
      this.reserveStatus = -1;
      this.getReserveList();
    },
    selectReserveStatus(e) {
      this.reserveStatus = e.value;
      this.getReserveList();
    },
    handleContextMenu(row) {
      console.log(row);
      if (row.reserveStatus == 1) {
        this.submitStatus = true;
      } else {
        this.submitStatus = false;
      }
    },
    showDetail() {
      this.$Message.success({
        content: "查看详情",
        duration: 3,
      });
    },
    adjustTime() {
      this.$Modal.confirm({
        render: (h) => {
          return h("Select", {
            props: {
              title: "调整时间",
              value: this.value,
              autofocus: true,
              placeholder: "请选择调整时间",
            },
            on: {
              input: (val) => {
                this.value = val;
              },
            },
          });
        },
      });
    },
    acceptReserve() {
      this.$Message.success({
        content: "接受咨询",
        duration: 3,
      });
    },
    rejectReserve() {
      this.$Modal.confirm({
        render: (h) => {
          return h("Input", {
            props: {
              value: this.value,
              autofocus: true,
              placeholder: "请输入您的驳回理由",
            },
            on: {
              input: (val) => {
                this.value = val;
              },
              ok: (val) => {
                console.log(val);
              },
              cancel: (val) => {
                console.log(val);
              },
            },
          });
        },
      });
    },
    handleSearch() {
      this.reserveStatus = -1;
      this.consultantId = -1;
      this.getReserveList();
    },
    getReserveList() {
      this.searching = true;
      this.$get("Reserve/GetReserveList", {
        keyword: this.inputValue,
        pageSize: this.pageSize,
        pageNum: this.pageNum,
        reserveStatus: this.reserveStatus,
        consultantId: this.consultantId,
      }).then((res) => {
        this.tData = res.data.responseList;
        this.searching = false;
        this.$Message.success({
          content: "加载成功",
          duration: 3,
        });
      });
    },
    changeSize(e) {
      this.pageSize = e;
      this.getReserveList();
    },
    changePage(e) {
      this.pageNum = e;
      this.getReserveList();
    },
  },
};
</script>

<style lang="less" scoped="scoped">
</style>
